import React from 'react';
import LogPage from '../../components/AuditResponse/LogPage';

const AuditLogs: React.FC = () => {
  // Update the columns to match TaskPanoModel
  return (
    <LogPage />

  );
};

export default AuditLogs;
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import Sidebar from './components/Common/Sidebar';
import { AuthProvider } from './context/AuthContext';
import { NotificationProvider } from './components/Common/Notifications';
import AppRoutes from './routes/AppRoutes';
import Login from './pages/Authentication/Login';
import { Project } from './models/Task/Pano';
import Navbar from './components/Common/Navbar';
import TaskMail from './pages/TaskManagement/TaskMail';
import PrivateRoute from './components/Common/PrivateRoute';
import ForgotPassword from './pages/Authentication/ForgotPassword';

const App: React.FC = () => {

  const [projectList, setAllProjectList] = useState<Project[]>([]);

  return (
    <AuthProvider>
      <NotificationProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/task-management/mail/:uuid/:answer" element={<TaskMail />} />
            <Route element={<PrivateRoute />}>
              <Route
                path="*"
                element={
                  <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <Navbar />
                    <Sidebar allProject={projectList} />
                    <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, marginTop: "10vh" }}>
                      <AppRoutes onProjectListChange={(allProjectList) => setAllProjectList(allProjectList)} />
                    </Box>
                  </Box>
                }
              />
            </Route>
          </Routes>
        </Router>
      </NotificationProvider>
    </AuthProvider>
  );
};

export default App;

import React from "react";
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Card, CardContent } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { useTranslation } from "react-i18next";


const AIDocumentSuggestion: React.FC = () => {

    const exampleSuggestionDoc = ["Test1.docx", "Test2.xlsx"];
    const { t } = useTranslation('global');

    return (
        <Card square sx={{ minWidth: "100%" }}>
            <CardContent>
                <Table>
                    <TableHead sx={{ backgroundColor: "whitesmoke", borderBottom: "1.5px black solid" }}>
                        <TableRow>
                            <TableCell style={{ fontWeight: "bold", fontSize: "15px" }}>
                                {t('task.ai_suggestion_doc')}
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold", fontSize: "15px" }}>
                                {t('task.actions')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {exampleSuggestionDoc && exampleSuggestionDoc.length > 0 ? (
                            exampleSuggestionDoc.map((doc, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        {doc}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton>
                                            <ReplyAllIcon />
                                        </IconButton>
                                        <IconButton>
                                            <DownloadIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : null
                        }

                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    )
}

export default AIDocumentSuggestion;
import React, { useState } from "react";
import { CardContent, TextField, Button, IconButton, InputAdornment, Alert, AlertColor } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import { AuthenticationService } from "../../services/api";
import { useAuth } from "../../context/AuthContext";



const ChangePassword: React.FC = () => {

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordAgain, setNewPasswordAgain] = useState("");

    const [showNewPassAgain, setShowNewPassAgain] = useState(false);
    const [showCurrentPass, setShowCurrentPass] = useState(false);
    const [showNewPass, setShowNewPass] = useState(false);
    const { t } = useTranslation('global');
    const [message, setMessage] = useState<string>("");
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [messageType, setMessageType] = useState<AlertColor>("success");
    const { logout } = useAuth();

    const [isMatch, setIsMatch] = useState<boolean>(false);


    const handleClickShowPassword = (value: string) => {

        if (value === "current") {
            setShowCurrentPass(!showCurrentPass)
        } else if (value === "new") {
            setShowNewPass(!showNewPass);
        } else if (value === "again") {
            setShowNewPassAgain(!showNewPassAgain);
        }
    };

    const clearData = () => {
        setCurrentPassword("");
        setNewPassword("");
        setNewPasswordAgain("");
    };

    const checkIsMatch = (value: string, field: string) => {

        if(field === "new"){
            if(value !== newPasswordAgain){
                setIsMatch(true)
            }else{
                setIsMatch(false);
            }
        }else if(field === "again"){
            if(value !== newPassword){
                setIsMatch(true);
            }else{
                setIsMatch(false);
            }
        }

    };

    const handlePasswordChange = (value: string, field: string) => {
        if (field === "new") {
            setNewPassword(value);
            checkIsMatch(value,"new");
        } else if (field === "again") {
            setNewPasswordAgain(value);
            checkIsMatch(value,"again")
        } else if (field === "current") {
            setCurrentPassword(value);
        }
    };

    const redirectToLogin = () => {
        logout();
        window.location.href = '/login';
    };

    const changePassword = async () => {
        try {
            let updateData = {
                "oldPassword": currentPassword,
                "newPassword": newPassword,
                "newPasswordAgain": newPasswordAgain
            };
            const response = await AuthenticationService.update("", updateData, "change-password");
            console.log("change pass res -> ", response);
            setShowMessage(true);
            setMessageType("success");
            setMessage(t('general.password_change'));
            setTimeout(() => {
                setShowMessage(false);
                redirectToLogin();
                clearData();
            }, 2000);
        } catch (error) {
            console.log("change password error: ", error);
            setShowMessage(true);
            setMessageType("error");
            setMessage(t('general.password_change_error'));
            setTimeout(() => {
                setShowMessage(false);
            }, 2000);
        }
    }

    return (
        <CardContent>
            <Grid container rowSpacing={3}>

                {showMessage ? (<Alert severity={messageType}>{message}</Alert>) : null}
                {isMatch ? (<Alert severity="error">{t('profile.isNotMatch')}</Alert>):null}

                <Grid size={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <TextField
                        onChange={(e) => { handlePasswordChange(e.target.value,"current") }}
                        value={currentPassword}
                        label={t('profile.current_password')}
                        type={showCurrentPass ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => handleClickShowPassword("current")}
                                        edge="end"
                                        aria-label="toggle password visibility"
                                    >
                                        {showCurrentPass ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid size={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <TextField
                        onChange={(e) => { handlePasswordChange(e.target.value,"new") }}
                        value={newPassword}
                        label={t('profile.new_password')}
                        type={showNewPass ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => handleClickShowPassword("new")}
                                        edge="end"
                                        aria-label="toggle password visibility"
                                    >
                                        {showNewPass ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}


                    />


                </Grid>
                <Grid size={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <TextField
                        onChange={(e) => handlePasswordChange(e.target.value,"again")}
                        value={newPasswordAgain}
                        label={t('profile.new_password_again')}
                        type={showNewPassAgain ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => handleClickShowPassword("again")}
                                        edge="end"
                                        aria-label="toggle password visibility"
                                    >
                                        {showNewPassAgain ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                </Grid>
                <Grid size={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                    <Button
                        variant="outlined"
                        size="medium"
                        sx={{
                            backgroundColor: "#102240",
                            color: "white",
                            "&.Mui-disabled": {
                                color: "white",
                                opacity: 0.5,
                            },
                        }}
                        component="span"
                        disabled={currentPassword === "" || newPassword === "" || newPasswordAgain === "" || isMatch}
                        onClick={() => { changePassword(); }}
                    >
                        {t('profile.change_your_password')}
                    </Button>
                </Grid>

            </Grid>
        </CardContent>
    )
}


export default ChangePassword;

import React, { useState, useEffect } from "react";
import { Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";

type StatusType = "active" | "on-hold" | "closed";

interface ProjectStatusProps {
    currentStatus: StatusType;
    onChange: (newStatus: string) => void;
};

const ProjectStatus: React.FC<ProjectStatusProps> = ({currentStatus, onChange}) => {
    const [status, setStatus] = useState<StatusType>("active");
    const { t } = useTranslation('global');
    const color: Record<StatusType, { backgroundColor: string; color: string }> = {
        active: { backgroundColor: "#2196F3", color: "#ffffff" },
        "on-hold": { backgroundColor: "#FFC107", color: "#000000" },
        closed: { backgroundColor: "#c10101", color: "#ffffff" },
    };

    useEffect(()=>{
        setStatus(currentStatus);
    },[currentStatus])

    const language = {
        "active": t('project.active'),
        "on-hold": t('project.on-hold'),
        "closed": t('project.closed')
    };

    const handleChangeStatus = (status: StatusType) => {
        setStatus(status);
        onChange(status);
    }

    return (
        <Select
            value={status}
            fullWidth
            style={{...color[status], height: "32px", width: "auto"}}
            onChange={(event) => handleChangeStatus(event.target.value as StatusType)}
            IconComponent={() => null}
            size="small"
        >
            <MenuItem style={color["active"]} value="active">
                {language["active"]}
            </MenuItem>
            <MenuItem style={color["on-hold"]} value="on-hold">
                {language["on-hold"]}
            </MenuItem>
            <MenuItem style={color["closed"]} value="closed">
                {language["closed"]}
            </MenuItem>
        </Select>
    );
};

export default ProjectStatus;

import React, { useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, IconButton, Card, CardContent } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';

import { Document } from "../../../models/Task/Pano";
import { TaskManagementService } from "../../../services/api";
import { useTranslation } from "react-i18next";

interface TaskFileTableProps {
    documents: Document[];
    onFileChange: (newDocuments: Document[]) => void;
    filePermission: boolean;
}

const TaskFileTable: React.FC<TaskFileTableProps> = ({ documents, onFileChange, filePermission }) => {

    const [allDocuments, setAllDocument] = useState<Document[]>([]);
    const { t } = useTranslation('global');

    useEffect(() => {
        setAllDocument(documents);
    }, [documents]);


    const deleteFile = (index: number) => {
        const newFiles = allDocuments.filter((_, i) => i !== index);
        setAllDocument(newFiles);
        onFileChange(newFiles);
    };

    const downloadFile = async (id: string, fileName: string) => {
        try {
            const response = await TaskManagementService.get("file/" + id, 'arraybuffer');
            const data = response;

            if (!data) {
                console.error("Response data is empty.");
                return;
            }

            const fileTypeIndex = fileName.lastIndexOf(".");
            const fileType = fileTypeIndex !== -1 ? fileName.substring(fileTypeIndex + 1) : 'application/octet-stream';


            const byteArray = new Uint8Array(data);

            const blob = new Blob([byteArray], { type: fileType });

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("File download error:", error);
        }
    };


    const decodeUTF8 = (input: string) => {
        const byteArray = new Uint8Array(input.split('').map(char => char.charCodeAt(0)));
        const decoder = new TextDecoder("utf-8");
        return decoder.decode(byteArray);
    };

    return (
        <Card square sx={{ minWidth: "100%" }}>
            <CardContent>
                <Table>
                    <TableHead sx={{ backgroundColor: "whitesmoke", borderBottom: "1.5px black solid" }}>
                        <TableRow>
                            <TableCell style={{ fontWeight: "bold", fontSize: "15px" }}>
                                {t('task.file_name')}
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold", fontSize: "15px" }}>
                                {t('task.actions')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allDocuments && allDocuments.length > 0 ? (
                            allDocuments.map((document, index) => (

                                <TableRow key={index}>
                                    <TableCell>
                                        {decodeUTF8(document.fileName)}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton disabled={filePermission} onClick={() => deleteFile(index)}>
                                            <DeleteIcon sx={{ color: "red" }} />
                                        </IconButton>
                                        <IconButton onClick={() => downloadFile(document.fileID, decodeUTF8(document.fileName))}>
                                            <DownloadIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : <TableRow>
                            <TableCell>
                                <p>{t('task.no_file')}</p>
                            </TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    )
}

export default TaskFileTable;
import React from "react";
import { Project } from "../../../models/Task/Pano";
import Grid from '@mui/material/Grid2';
import { Typography, Card, CardContent, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import TaskUserSelect from "./TaskUserSelect";
import ProjectStatus from "./ProjectStatus";
import { TaskManagementService } from "../../../services/api";

interface ProjectDetailProps {
    project?: Project;
};

type StatusType = "active" | "on-hold" | "closed";


const ProjectDetail: React.FC<ProjectDetailProps> = ({ project }) => {
    const { t } = useTranslation('global');


    const update = async (newStatus: string) => {
        if(project){
            const updateProject = {...project};
            updateProject.projectStatus = newStatus;
            const response = await TaskManagementService.update(project.code, updateProject, "project");
            console.log("project update -> ", response);
        }
    };

    return (
        <Card sx={{ marginTop: 3, width: "auto", maxWidth: "100%", boxShadow: 5, borderRadius: 2 }}>
            <CardContent>
                <Grid container spacing={1} alignItems="center">
                    <Grid size={6}>
                        <Typography variant="body1" style={{ fontWeight: "bold", color: "#0b2343" }}>{t("project.name")}</Typography>
                    </Grid>
                    <Grid size={6}>
                        <Typography variant="body1" ml={1} style={{ fontWeight: "bold", color: "#0b2343" }}>{project?.name}</Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={1} alignItems="center" mt={4}>
                    <Grid size={6}>
                        <Typography variant="body1" style={{ fontWeight: "bold", color: "#0b2343" }}>{t("project.project_id")}</Typography>
                    </Grid>
                    <Grid size={6}>
                        <Typography variant="body1" ml={1} style={{ fontWeight: "bold", color: "#0b2343" }}>{project?.code}</Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={1} alignItems="center" mt={3}>
                    <Grid size={6}>
                        <Typography variant="body1" style={{ fontWeight: "bold", color: "#0b2343" }}>{t("project.description")}</Typography>
                    </Grid>
                    <Grid size={6}>
                        <Tooltip title={project?.description && project?.description.length >= 15 ? project?.description : null}>
                            <Typography variant="body1" ml={1} style={{ fontWeight: "bold", color: "#0b2343" }}>
                                {project?.description && project?.description.length >= 15 ? project?.description.slice(0, 15) + "..." : project?.description}
                            </Typography>
                        </Tooltip>
                    </Grid>
                </Grid>

                <Grid container spacing={1} alignItems="center" mt={3}>
                    <Grid size={6}>
                        <Typography variant="body1" style={{ fontWeight: "bold", color: "#0b2343" }}>{t("project.coordinator")}</Typography>
                    </Grid>
                    <Grid size={6}>
                        <Typography variant="body1">
                            <TaskUserSelect user={project?.coordinator || ""} onTaskUserChange={() => { }} permission={true} show={true} filter="Participant" />
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={1} alignItems="center" mt={1}>
                    <Grid size={6}>
                        <Typography variant="body1" style={{ fontWeight: "bold", color: "#0b2343" }}>{t("project.independent_auditor")}</Typography>
                    </Grid>
                    <Grid size={6}>
                        <TaskUserSelect user={project?.independentAuditor || ""} onTaskUserChange={() => { }} permission={true} show={true} filter="Independent Auditor" />
                    </Grid>
                </Grid>

                <Grid container spacing={1} alignItems="center" mt={1}>
                    <Grid size={6}>
                        <Typography variant="body1" style={{ fontWeight: "bold", color: "#0b2343" }}>{t("project.project_status")}</Typography>
                    </Grid>
                    <Grid size={6}>
                        <ProjectStatus currentStatus={project?.projectStatus as StatusType} onChange={(newStatus) => {update(newStatus)}} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ProjectDetail;

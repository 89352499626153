import React, { useState, useEffect, useCallback } from "react";
import Grid from '@mui/material/Grid2';
import { Button, TextField, InputAdornment, IconButton } from '@mui/material';


import FileService from "../../../services/FileService";
import { TaskManagementService } from "../../../services/api";
import { Task } from "../../../models/Task/Pano";
import TaskFilter from "./TaskFilter";
import TaskSort from "./TaskSort";
import { useTranslation } from "react-i18next";
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import { useAuth } from "../../../context/AuthContext";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';


interface TaskHeaderProps {
    projectCode: string;
    onRefresh: (refresh: boolean) => void;
    disabledUploadButton: boolean;
    onChangeSearch: (search: string) => void;
    onSortChange: (column: string, sort: string) => void;
    onConditionsChange: (conditions: Array<Record<string, any>>) => void;
    coordinator: string;
}

const TaskHeader: React.FC<TaskHeaderProps> = ({ projectCode, onRefresh, disabledUploadButton, onChangeSearch, onSortChange, onConditionsChange, coordinator }) => {

    const fileService = new FileService();
    const [message, setMessage] = useState<boolean>(false);
    const [projectName, setProjectName] = useState<string>("");
    const [currentProjectIndependentAuditor, setCurrentProjectIndependenAuditor] = useState<string>("");
    const [search, setSearch] = useState<string>("");
    const { t } = useTranslation('global');
    const { i18n } = useTranslation();
    const uploadedExcelColumns = i18n.language === 'tr' ? ["Ref No", "Durum", "Başlık", "Madde", "Paragraf", "Görev", "Talep Tarihi", "Contact"] : ["Ref Num", "Status", "Title", "Task", "Article", "Paragraph", "Request Date", "Contact"];
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { user } = useAuth();

    const [open, setOpen] = useState<boolean>(false);


    const downloadTask = {
        taskId: "Task-ID",
        refNum: "Ref Num",
        regulationRef: "Regulation Ref.",
        task: "Task",
        requestDate: "Request Date",
        responsible: "Responsible",
        approver: "Approver",
        internalAuditor: "Internal Auditor",
        independentAuditor: "Indepent Auditor",
        priority: "Priority",
        status: "Status",
        creationDate: "Creation Date",
        targetDate: "Target Date",
        response: "Response",
        documents: "Docs",
        parentTaskId: "Parent Task-ID"
    };


    const convertExcelDateToLocalDate = (excelDate: number): Date | null => {
        if (excelDate < 1) return null;

        const excelBaseDate = new Date(Date.UTC(1900, 0, 1));
        const utcDate = new Date(excelBaseDate.getTime() + (excelDate - 1) * 86400000);

        const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);
        return localDate;
    };


    const readProjectDetails = useCallback(async () => {
        const response = await TaskManagementService.get("project/" + projectCode, "json");
        setProjectName(response.name);
        setCurrentProjectIndependenAuditor(response.independentAuditor);
    }, [projectCode]);

    useEffect(() => {
        readProjectDetails();
    }, [readProjectDetails]);


    const swappedStatusLanguage: Record<string, string> = {
        [t('task.cancelled')]: "Cancelled",
        [t('task.not_assigned')]: "Not Assigned",
        [t('task.to_do')]: "To Do",
        [t('task.in_progress')]: "In Progress",
        [t('task.awaiting_approver')]: "Awaiting Approver",
        [t('task.awaiting_int_auditor')]: "Awaiting Int. Auditor",
        [t('task.submitted')]: "Submitted",
        [t('task.done')]: "Done"
    };

    const redirectToProjectSettings = () => {
        navigate("/task-management/project-settings/" + projectCode);
    };

    const handleFileUpload = async (jsonData: any) => {
        if (Array.isArray(jsonData)) {
            setLoading(true); // İşlem başlıyor
            for (let index = 0; index < jsonData.length; index++) {
                const item = jsonData[index];

                let valid = true;
                for (const key of Object.keys(item)) {
                    if (!uploadedExcelColumns.includes(key)) {
                        setMessage(true);
                        setTimeout(() => {
                            setMessage(false);
                        }, 5000);
                        valid = false;
                        break;
                    }
                }

                if (!valid) {
                    break;
                }

                if (typeof item === 'object' && item !== null) {
                    let newTask: Task = {} as Task;
                    Object.entries(item).forEach(([key, value]) => {
                        switch (key) {
                            case t('task.ref_num'):
                                newTask.refNum = typeof value === "number" ? String(value) : "";
                                break;
                            case t('task.status'):
                                newTask.status = typeof value === "string" && value !== "" ? swappedStatusLanguage[value] : "Not Assigned";
                                break;
                            case t('task.title'):
                                newTask.title = typeof value === "string" ? value : "";
                                break;
                            case t('task.task'):
                                newTask.task = typeof value === "string" ? value : "";
                                break;
                            case t('task.article'):
                                newTask.article = typeof value === "number" ? String(value) : "";
                                break;
                            case t('task.paragraph'):
                                newTask.paragraph = typeof value === "number" ? String(value) : "";
                                break;
                            case t('task.request_date'):
                                if (typeof value === "string") {
                                    const [day, month, year] = value.split(".");
                                    const parsedDate = new Date(Date.UTC(Number(year), Number(month) - 1, Number(day)));
                                    newTask.requestDate = parsedDate;
                                } else {
                                    const date = convertExcelDateToLocalDate(value as number);
                                    newTask.requestDate = date;
                                }
                                break;

                        }
                    });
                    newTask.creationDate = new Date();
                    newTask.response = "";
                    newTask.priority = "";
                    newTask.notes = "";
                    newTask.lastUpdate = new Date();
                    newTask.documents = [];
                    newTask.comments = [];
                    newTask.subtasks = [];
                    newTask.targetDate = null;
                    newTask.regulationRef = newTask.title + "/" + newTask.article + "/" + newTask.paragraph;
                    newTask.projectCode = projectCode || "";
                    newTask.independentAuditor = null;
                    newTask.responsible = null;
                    newTask.approver = null;
                    newTask.internalAuditor = null;

                    console.log("giden task -> ", newTask);

                    const response = await TaskManagementService.create(newTask, "task");
                    console.log("my task create response -> ", response);
                }
            }
            setLoading(false); // İşlem bitti
            onRefresh(true);
        } else {
            console.log("Expected an array but received:", jsonData);
        }
    };



    const getAllTask = async () => {
        const url = "task/read-all/" + projectCode;
        const response = await TaskManagementService.getAllWithParam({ "conditions": [] }, url);
        prepareXlsx(response);
    };

    const prepareDate = (date: Date | null) => {
        if (!date) return "";

        const d = new Date(date);
        const day = d.getDate().toString().padStart(2, '0');
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const year = d.getFullYear();

        return `${day}.${month}.${year}`;
    };

    const prepareXlsx = (allTask: Task[]) => {
        const formattedTasks: { [key: string]: any }[] = [];

        allTask.forEach((task) => {
            const myTask: { [key: string]: any } = {
                [downloadTask.taskId]: task.taskID || "N/A",
                [downloadTask.parentTaskId]: "N/A",
                [downloadTask.refNum]: task.refNum || "N/A",
                [downloadTask.regulationRef]: task.regulationRef || "N/A",
                [downloadTask.task]: task.task || "N/A",
                [downloadTask.requestDate]: prepareDate(task.requestDate || null) || "N/A",
                [downloadTask.responsible]: task.responsible || "N/A",
                [downloadTask.approver]: task.approver || "N/A",
                [downloadTask.internalAuditor]: task.internalAuditor || "N/A",
                [downloadTask.independentAuditor]: task.independentAuditor || "N/A",
                [downloadTask.priority]: task.priority || "N/A",
                [downloadTask.status]: task.status || "N/A",
                [downloadTask.creationDate]: prepareDate(task.creationDate || null) || "N/A",
                [downloadTask.targetDate]: prepareDate(task.targetDate || null) || "N/A",
                [downloadTask.response]: task.response || "N/A",
                [downloadTask.documents]: task.documents.map(doc => doc.fileName).join(", ") || "N/A",
            };

            formattedTasks.push(myTask);

            if (task.subtasks.length > 0) {
                task.subtasks.forEach((subtask) => {
                    const sub: { [key: string]: any } = {
                        [downloadTask.taskId]: subtask.taskID || "N/A",
                        [downloadTask.parentTaskId]: task.taskID || "N/A",
                        [downloadTask.refNum]: task.refNum || "N/A",
                        [downloadTask.regulationRef]: task.regulationRef || "N/A",
                        [downloadTask.task]: subtask.task || "N/A",
                        [downloadTask.requestDate]: "N/A",
                        [downloadTask.responsible]: subtask.responsible || "N/A",
                        [downloadTask.approver]: "N/A",
                        [downloadTask.internalAuditor]: "N/A",
                        [downloadTask.independentAuditor]: "N/A",
                        [downloadTask.priority]: subtask.priority || "N/A",
                        [downloadTask.status]: subtask.status || "N/A",
                        [downloadTask.creationDate]: prepareDate(subtask.creationDate || null) || "N/A",
                        [downloadTask.targetDate]: prepareDate(subtask.targetDate || null) || "N/A",
                        [downloadTask.response]: subtask.response || "N/A",
                        [downloadTask.documents]: subtask.documents.map(doc => doc.fileName).join(", ") || "N/A",
                    };
                    formattedTasks.push(sub);
                });
            }
        });

        console.log("Formatted Tasks: ", formattedTasks);
        fileService.jsonToXlsx(formattedTasks, projectName);
    };


    const downloadExampleExcel = () => {
        const lang = i18n.language;

        const formatDate = (date: Date): string => {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}.${month}.${year}`;
        };

        const exampleData = [
            {
                [lang === "tr" ? "Ref No" : "Ref Num"]: 1,
                [lang === "tr" ? "Durum" : "Status"]: lang === "tr" ? "Atanmadı" : "Done",
                [lang === "tr" ? "Başlık" : "Title"]: "Yönetim Gözetimi, Roller ve Sorumluluklar",
                [lang === "tr" ? "Madde" : "Article"]: 2,
                [lang === "tr" ? "Paragraf" : "Paragraph"]: 3,
                [lang === "tr" ? "Görev" : "Task"]: "Example Task",
                [lang === "tr" ? "Talep Tarihi" : "Request Date"]: formatDate(new Date("2024-09-05")),
                "Contact": "",
            },
            {
                [lang === "tr" ? "Ref No" : "Ref Num"]: 2,
                [lang === "tr" ? "Durum" : "Status"]: lang === "tr" ? "Devam Ediyor" : "In Progress",
                [lang === "tr" ? "Başlık" : "Title"]: "Information Systems policy, procedure and process documents",
                [lang === "tr" ? "Madde" : "Article"]: 3,
                [lang === "tr" ? "Paragraf" : "Paragraph"]: 4,
                [lang === "tr" ? "Görev" : "Task"]: "Example Task - 1",
                [lang === "tr" ? "Talep Tarihi" : "Request Date"]: formatDate(new Date("2024-04-18")),
                "Contact": "",
            }
        ];

        const fileName = lang === "tr" ? "turkish-excel-example" : "english-excel-example";

        fileService.jsonToXlsx(exampleData, fileName);
    };

    return (
        <Grid container spacing={4} justifyContent="flex-start" size={12} sx={{ mb: 5, mt: 2 }}>
            <Grid size={1} >
                <Button
                    variant="outlined"
                    size='medium'
                    sx={{
                        backgroundColor: "#6b6b6b",
                        color: "white",
                        mr: 15,
                        whiteSpace: "nowrap"
                    }}
                    onClick={() => { getAllTask() }}
                    fullWidth
                >
                    {t('task.export_task')}
                </Button>
            </Grid>
            <Grid size={1}>

                <div>
                    <Button
                        variant="outlined"
                        sx={{
                            backgroundColor: "#102240",
                            color: "white",
                            whiteSpace: "nowrap",
                        }}
                        endIcon={open ? <KeyboardArrowDownIcon /> : <KeyboardArrowLeftIcon />}
                        onClick={() => {
                            setOpen(!open);
                        }}
                        fullWidth
                        size="medium"
                    >
                        {t("task.upload")}
                    </Button>
                    {open && (
                        <>
                            <input
                                type="file"
                                accept=".xlsx, .xls"
                                style={{ display: "none" }}
                                onChange={(event) => fileService.handleFileChange(event, handleFileUpload)}
                                id="upload-excel"
                                disabled={projectCode === "" || disabledUploadButton}
                            />
                            <label htmlFor="upload-excel">
                                <Button
                                    variant="outlined"
                                    size="medium"
                                    sx={{
                                        backgroundColor: "#6b6b6b",
                                        color: "white",
                                        marginTop: 1,
                                        "&.Mui-disabled": {
                                            color: "white",
                                            backgroundColor: "lightgray",
                                        },
                                        whiteSpace: "nowrap",
                                        width: "auto"
                                    }}
                                    fullWidth
                                    disabled={projectCode === "" || disabledUploadButton}
                                    component="span"
                                    endIcon={<FileUploadIcon />}
                                >
                                    {t("task.upload_task")}
                                </Button>
                                {loading && <CircularProgress size={20} sx={{ marginLeft: 1 }} />}
                            </label>
                            <Button
                                variant="outlined"
                                onClick={() => { downloadExampleExcel() }}
                                sx={{
                                    backgroundColor: "#6b6b6b",
                                    color: "white",
                                    whiteSpace: "nowrap",
                                    marginTop: 1,
                                    width: "auto"
                                }}
                                fullWidth
                                endIcon={<DownloadIcon />}
                            >
                                {t("task.example_excel")}
                            </Button>
                        </>
                    )}
                </div>

                {message && (
                    <div>
                        <p style={{ color: "red" }}>{t('task.check_file_message')}</p>
                    </div>
                )}
            </Grid>


            <Grid size={3}>
                <TextField
                    fullWidth
                    size="small"
                    label={t('task.search')}
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value);
                        onChangeSearch(e.target.value);
                    }}
                    slotProps={{
                        input: {
                            endAdornment: search !== "" && (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => {
                                            setSearch("");
                                            onChangeSearch("");
                                        }}
                                        edge="end"
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        },
                    }}
                />
            </Grid>

            <Grid container rowSpacing={2}>
                <Grid size={2}>
                    <TaskFilter onConditionsChange={(conditions) => { onConditionsChange(conditions) }} />
                </Grid>
                <Grid size={2} sx={{ marginLeft: "10px" }}>
                    <TaskSort onSortChange={(column, sort) => { onSortChange(column, sort) }} />
                </Grid>
                <Grid size={2}>
                    <IconButton onClick={() => { redirectToProjectSettings() }} disabled={user?._id !== coordinator}>
                        <SettingsIcon />
                    </IconButton>
                </Grid>
            </Grid>

        </Grid>
    )
}



export default TaskHeader;

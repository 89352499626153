import React, { useState } from "react";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { IconButton, TextField, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { useTranslation } from "react-i18next";

const AIResponseSuggestion: React.FC = () => {
    const [num, setNum] = useState<number>(0);
    const {t} = useTranslation('global');


    return (
        <Grid container size={12}>
            <Grid size={12}>
                <TextField
                    label={t("task.ai_suggestion_response")}
                    value={"Example ai suggestion " + num}
                    fullWidth
                    multiline
                    rows={3}
                    slotProps={{
                        input: {

                            endAdornment: (

                                <div style={{display: "flex"}}>
                                    <Tooltip title={t("task.copy_ai_suggestion")}>
                                        <IconButton>
                                            <ReplyAllIcon />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title={t("task.new_suggestion")}>
                                        <IconButton onClick={() => { setNum(num + 1) }}>
                                            <SmartToyIcon fontSize="large" style={{ color: "darkslateblue" }} />
                                        </IconButton>
                                    </Tooltip>
                                </div>

                            ),
                        },
                    }} />
            </Grid>
        </Grid>
    )
}

export default AIResponseSuggestion;
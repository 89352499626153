import React, { useState } from 'react';
import { Box, Tabs, Tab, Typography, Paper } from '@mui/material';
import AuditLogsPano from './AuditLogsPano';
import Grid from '@mui/material/Grid2';
import { useTranslation } from 'react-i18next';


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const LogPage: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { t } = useTranslation('global');


  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 3, borderRadius: 2 }}>
      <Grid size={12} sx={{ margin: 5 }}>
        <Typography variant="h4" sx={{ color: "#0b2343", marginTop: "15px" }}>
          {t("audit_logs.audit_logs")}
        </Typography>
      </Grid>


      <Grid size={12} sx={{ margin: 5 }}>

        <Grid size={12} sx={{ marginLeft: 2 }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{ backgroundColor: '#fff', borderBottom: 1, borderColor: 'divider' }}
          >
            <Tab label={t("audit_logs.user")} />
            <Tab label={t("audit_logs.role")} />
            <Tab label={t("audit_logs.permission")} />
            <Tab label={t("audit_logs.task")} />
          </Tabs>
        </Grid>


        <TabPanel value={selectedTab} index={0}>
          <AuditLogsPano logType="user" />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <AuditLogsPano logType="role" />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <AuditLogsPano logType="permission" />
        </TabPanel>
        <TabPanel value={selectedTab} index={3}>
          <AuditLogsPano logType="task" />
        </TabPanel>
      </Grid>
    </Paper>
  );
};

export default LogPage;

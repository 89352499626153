import React, { useEffect, useState, useCallback } from "react";
import { FormGroup, FormControlLabel, Switch, Button, Alert, AlertColor } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { useTranslation } from "react-i18next";
import { Project } from "../../../models/Task/Pano";
import { TaskManagementService } from "../../../services/api";


interface ProjectNotifacationProps {
    project?: Project;
};


const ProjectNotification: React.FC<ProjectNotifacationProps> = ({ project }) => {
    const { t } = useTranslation('global');

    const [userAssigned, setUserAssigned] = useState<boolean>(false);
    const [statusMail, setStatusMail] = useState<boolean>(false);
    const [approve, setApprove] = useState<boolean>(false);
    const [revisionRequired, setRevisionRequired] = useState<boolean>(false);
    const [myProject, setMyProject] = useState<Project>();

    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [alert, setAlert] = useState<AlertColor>('success');

    const getMyProjectNotification = useCallback(() => {
        if (project?.settings.emailNotifications) {
            setMyProject(project);
            setUserAssigned(project.settings.emailNotifications.onUserAssignment);
            setStatusMail(project.settings.emailNotifications.onStatusChange);
            setRevisionRequired(project.settings.emailNotifications.onRevisionRequired);
            setApprove(project.settings.emailNotifications.onTaskReadyApproval);
        }
    }, [project]);

    useEffect(() => {
        getMyProjectNotification();
    }, [getMyProjectNotification]);



    const language = {
        "user_assigned": (
            <>
                <span style={{ fontWeight: "bold", color: "#0b2343" }}>{t('project.user_assigned_title')}</span> {t('project.user_assigned')}
            </>
        ),
        "status_mail": (
            <>
                <span style={{ fontWeight: "bold", color: "#0b2343" }}>{t('project.status_mail_title')}</span> {t('project.status_mail')}
            </>
        ),
        "approve_title": (
            <>
                <span style={{ fontWeight: "bold", color: "#0b2343" }}>{t('project.status_approve_title')}</span> {t('project.status_approve')}
            </>
        ),
        "revision": (
            <>
                <span style={{ fontWeight: "bold", color: "#0b2343" }}>{t('project.revision_required_title')}</span> {t('project.revision_required')}
            </>
        )
    };


    const updateProject = async () => {
        let newEmailNotification = {
            onStatusChange: statusMail ?? false,
            onRevisionRequired: revisionRequired ?? false,
            onUserAssignment: userAssigned ?? false,
            onTaskReadyApproval: approve ?? false,
        };

        if (myProject) {
            try {
                const newProject = { ...myProject }
                newProject.settings.emailNotifications = newEmailNotification;
                setMyProject(newProject);
                const response = await TaskManagementService.update(newProject.code, newProject, "project");
                setMessage(t('project.project_notification_success'));
                setAlert('success');
                setShowMessage(true);
                console.log("notification response -> ", response);
            } catch (error) {
                setMessage(t('project.project_notification_error'));
                setAlert('error');
                setShowMessage(true);
                console.log(error);
            }

        }

        setTimeout(() => {
            setShowMessage(false);
        }, 3000)
    };



    return (

        <Grid container mt={3}>
            {showMessage ? (
                <Grid size={12}>
                    <Alert severity={alert} style={{width: "40%"}}>
                        {message}
                    </Alert>
                </Grid>
            ) : null}

            <Grid size={8} >
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={userAssigned}
                                onChange={(event) => setUserAssigned(event.target.checked)}
                            />
                        }
                        label={language["user_assigned"]}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={statusMail}
                                onChange={(event) => setStatusMail(event.target.checked)}
                            />
                        }
                        label={language["status_mail"]}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={approve}
                                onChange={(event) => setApprove(event.target.checked)}
                            />
                        }
                        label={language["approve_title"]}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={revisionRequired}
                                onChange={(event) => setRevisionRequired(event.target.checked)}
                            />
                        }
                        label={language["revision"]}
                    />
                </FormGroup>

            </Grid>

            <Grid size={12} mt={4} spacing={3} style={{ display: 'flex', gap: '16px' }}>
                <Grid>
                    <Button variant="contained" size="small" style={{ backgroundColor: "#0b2343" }} onClick={() => { updateProject(); }}>{t('general.save')}</Button>
                </Grid>
                <Grid>
                    <Button variant="contained" size="small" style={{ backgroundColor: "#c10101" }} onClick={() => { getMyProjectNotification() }}>{t('general.cancel')}</Button>
                </Grid>
            </Grid>
        </Grid>
    )

}

export default ProjectNotification
import React, { useState, useEffect, useCallback, SyntheticEvent } from 'react';
import { Dialog, DialogActions, DialogTitle, IconButton, DialogContent, Card, CardContent, InputLabel } from '@mui/material';
import Grid from '@mui/material/Grid2';
import CloseIcon from '@mui/icons-material/Close';

import TaskNameField from './TaskNameField';
import TaskStatusField from './TaskStatusField';
import TaskCombinedField from './TaskCombinedField';
import TaskAnswerField from './TaskAnswerField';
import TaskUserDetail from './TaskUserDetail';
import TaskCommentAndHistory from './TaskCommentAndHistory';
import TaskPriorityField from './TaskPriorityField';
import TaskFileUploadField from './TaskFileUploadField';
import TaskFileTable from './TaskFileTable';
import { Subtask, Task } from '../../../models/Task/Pano';
import { TaskManagementService } from '../../../services/api';
import { History } from '../../../models/Task/Pano';
import { useTranslation } from 'react-i18next';
import AIDocumentSuggestion from './AIDocumentSuggestion';
import SmartToyIcon from '@mui/icons-material/SmartToy';


// Define the interface for props

interface SubtaskDetailProps {
    open: boolean;
    onClose: () => void;
    subtaskIndex: number;
    parentTaskID: string;
    subtaskID: string;
    onRefresh: (refresh: boolean) => void;
}

const SubtaskDetail: React.FC<SubtaskDetailProps> = ({ open, onClose, subtaskIndex, onRefresh, parentTaskID, subtaskID }) => {

    const [id, setID] = useState<string>("");
    const [parentID, setParentID] = useState<string>("");
    const [subtask, setSubtask] = useState<Subtask>();
    const [parentTask, setParentTask] = useState<Task>();
    const [subIndex, setSubIndex] = useState<number>();
    const [currentTab, setCurrentTab] = useState<string>("");
    const [isScrolledToBottom, setIsScrolledToBottom] = useState<boolean>(false);
    const [allHistory, setAllHistory] = useState<History[]>([]);
    const [historyNum, setHistoryNum] = useState<number>(1);
    const [hist, setHist] = useState<History[]>([]);
    const { t } = useTranslation('global');

    useEffect(() => {
        setSubIndex(subtaskIndex);
        setID(subtaskID);
        setParentID(parentTaskID);
    }, [subtaskIndex, parentTaskID, subtaskID,]);

    const getAllHistory = useCallback(async (subtaskID: string) => {
        if (subtaskID !== "" && currentTab === "history") {
            const response = await TaskManagementService.get("history/" + subtaskID, "json");
            const history: History[] = response[0]?.history as History[];
            setAllHistory(history);
            setHist(history.slice(0, 10 * historyNum));
        }
    }, [historyNum, currentTab])

    useEffect(() => {
        const getMyTask = async () => {
            if (parentID !== "" && id !== "") {
                const response = await TaskManagementService.get("task/" + parentID, "json");
                const taskData: Task = response as Task;
                setParentTask(taskData);
                const selectedSubtask = taskData.subtasks.find((sub) => sub.taskID === id);
                console.log("benim detaydaki subtask useeffect -> ", selectedSubtask);
                setSubtask(selectedSubtask);
                getAllHistory(selectedSubtask?.taskID || "")
                setID(selectedSubtask?.taskID || "");
            } else {
                console.log("task id not found parent task id");
            }
        };

        getMyTask();
    }, [parentID, id, getAllHistory]);



    const getMyTask = useCallback(async () => {
        if (parentID !== "" && id !== "") {
            const response = await TaskManagementService.get("task/" + parentID, "json");
            const taskData: Task = response as Task;
            setParentTask(taskData);
            const selectedSubtask = taskData.subtasks.find((sub) => sub.taskID === id);
            setSubtask(selectedSubtask);
            getAllHistory(selectedSubtask?.taskID || "")
            setID(selectedSubtask?.taskID || "");
        } else {
            console.log("task id not found parent task id");
        }

    }, [getAllHistory, parentID, id]);

    useEffect(() => {
        getMyTask();
    }, [getMyTask]);

    const subTaskUpdate = async (field: string, value: any) => {
        const newSubtask = { ...subtask, [field]: value };
        setSubtask(newSubtask as Subtask);
        if (newSubtask && newSubtask.taskID) {
            if (["status", "responsible", "targetDate", "priority", "comments", "documents"].includes(field)) {
                const response = await TaskManagementService.update(newSubtask.taskID, newSubtask, "task/subtask");
                console.log("my subtask update detail response -> ", response);
                onRefresh(true);
                setSubtask(newSubtask as Subtask);
            }

        } else {
            console.error("taskID is required but missing");
        }
    };



    const blurUpdate = async (blurValue: boolean) => {

        if (blurValue && subtask) {
            const response = await TaskManagementService.update(subtask.taskID, subtask, "task/subtask");
            console.log("my subtask update detail response -> ", response);
            onRefresh(true);
            getMyTask();

        } else {
            console.log("blur value -> ", blurValue);
        }
    };

    const fileUpload = async (file: File) => {
        const parent = { ...parentTask }
        if (parent._id) {
            const formData = new FormData();
            formData.append("files", file);
            const fileUrl = "file/" + parent.taskID + "/" + subtask?.taskID;
            const response = await TaskManagementService.create(formData, fileUrl);
            console.log("my parent task file up response -> ", response);
            getMyTask();
        }
    };

    const handleScroll = (e: SyntheticEvent<HTMLElement>) => {
        const target = e.target as HTMLElement;
        const bottom = target.scrollHeight - target.scrollTop - target.clientHeight < 20;  // 10px tolerans
        if (bottom && !isScrolledToBottom) {
            setIsScrolledToBottom(true);
            console.log('Aşağı indin');
            if (historyNum * 10 < allHistory.length && currentTab === "history") {
                console.log("if içi");
                setHistoryNum(historyNum + 1);
                console.log("historyNum -> ", historyNum)
                console.log("hist leng -> ", hist.length);
                console.log("---------");
            } else {
                console.log("else içi");
                console.log("allHistory length -> ", allHistory.length)
                console.log("historyNum -> ", historyNum);
                console.log("hist leng -> ", hist.length);
                console.log("---------");
            }

        } else if (!bottom && isScrolledToBottom) {
            setIsScrolledToBottom(false);
        }
    };


    return (

        <Dialog
            open={open}
            onClose={() => { onClose(); setHistoryNum(1); setParentID(""); setID(""); }}
            maxWidth="lg"
            fullWidth
            PaperProps={{ style: { height: "100vh", width: "100vw", margin: 10 } }}
        >
            <DialogActions style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <DialogTitle style={{ margin: 0 }}>
                    <div>
                        {t('task.subtask_id')}: <span>{subtask?.taskID}</span>
                    </div>                </DialogTitle>
                <IconButton onClick={() => { onClose(); setHistoryNum(1); }}>
                    <CloseIcon />
                </IconButton>
            </DialogActions>
            <DialogContent
                style={{
                    maxHeight: "70vh",
                    overflowY: "auto",
                    scrollbarWidth: "thin",
                    scrollbarColor: "#6b6b6b #f1f1f1",
                }}
                onScroll={(e) => handleScroll(e)}

            >
                <Grid container spacing={3} columns={12}>
                    <Grid container spacing={3}>
                        <Grid container size={6} >
                            <Grid size={12}>
                                <Card square>
                                    <CardContent>
                                        <TaskCombinedField
                                            item={parentTask?.regulationRef || ""}
                                            multiline={true}
                                            rows={3}
                                            label={true}
                                            disabled={true}
                                            onBlurCallBack={() => { }}
                                            onTaskCombinedFieldChange={() => { }}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid size={12}>
                                {subtask?.subtaskPermissions.every((subPerm) => subPerm.permissions.task !== "UnvisibleF") ? (
                                    <Card square>
                                        <CardContent>
                                            <TaskNameField
                                                item={subtask?.task || ""}
                                                multiline={true}
                                                rows={3}
                                                onTaskNameChange={(newValue) => { subTaskUpdate("task", newValue) }}
                                                onBlurCallBack={(isBlurred) => { blurUpdate(isBlurred) }}
                                                permission={!subtask?.subtaskPermissions.some((subPerm) => subPerm.permissions.task === "Editable")}
                                                label={false}
                                            />
                                        </CardContent>
                                    </Card>
                                ) : null}
                            </Grid>
                            <Grid size={12}>
                                {subtask?.subtaskPermissions.every((subPerm) => subPerm.permissions.response !== "UnvisibleF") ? (
                                    <Card>
                                        <CardContent>
                                            <TaskAnswerField
                                                item={subtask?.response || ""}
                                                multiline={true}
                                                rows={3}
                                                onTaskAnswerChange={(newValue) => { subTaskUpdate("response", newValue) }}
                                                onBlurCallBack={(isBlurred) => { blurUpdate(isBlurred) }}
                                                permission={!subtask?.subtaskPermissions.some((subPerm) => subPerm.permissions.response === "Editable")}
                                                label={false}
                                            />
                                        </CardContent>
                                    </Card>
                                ) : null}
                            </Grid>
                        </Grid>
                        <Grid container size={6} justifyContent="center">

                            <Grid size={12} >
                                {subtask?.subtaskPermissions.every((subPerm) => subPerm.permissions.status === "UnvisibleF") && subtask?.subtaskPermissions.every((subPerm) => subPerm.permissions.priority === "UnvisibleF") ? (null) : (
                                    <Card>
                                        <CardContent>
                                            <Grid container spacing={2}>
                                                <Grid size={6}>
                                                    {subtask?.subtaskPermissions.every((subPerm) => subPerm.permissions.status !== "UnvisibleF") ? (
                                                        <div>
                                                            <InputLabel>{t('task.status')}</InputLabel>
                                                            <TaskStatusField
                                                                item={subtask?.status || ""}
                                                                onStatusChange={(newStatus) => { subTaskUpdate("status", newStatus) }}
                                                                allStatus={subtask?.possibleNextStatus.map((status) => status) || []}
                                                                permission={!subtask?.subtaskPermissions.some((subPerm) => subPerm.permissions.status === "Editable")}
                                                            />
                                                        </div>
                                                    ) : null
                                                    }
                                                </Grid>
                                                <Grid size={6}>
                                                    {subtask?.subtaskPermissions.every((subPerm) => subPerm.permissions.status !== "UnvisibleF") ? (
                                                        <div>
                                                            <InputLabel>{t('task.priority')}</InputLabel>
                                                            <TaskPriorityField
                                                                item={subtask?.priority || ""}
                                                                onPriorityChange={(newPriority) => { subTaskUpdate("priority", newPriority) }}
                                                                priorityPermission={!subtask?.subtaskPermissions.some((subPerm) => subPerm.permissions.priority === "Editable")}
                                                            />
                                                        </div>
                                                    ) : null}
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                )}
                            </Grid>

                            <Grid size={12}>
                                <Card square sx={{ backgroundColor: "whitesmoke" }}>
                                    <CardContent>
                                        <TaskUserDetail
                                            selectedTicketOwner={subtask?.responsible || ""}
                                            selectedTicketOwnerManager=''
                                            selectedInternalAuditor=''
                                            selectedIndependentAuditor=''
                                            targetDate={subtask?.targetDate || null}
                                            onTicketOwnerChange={(newTicketOwner) => { subTaskUpdate("responsible", newTicketOwner) }}
                                            onTicketOwnerManagerChange={(newTicketOwnerManager) => { console.log("newTicketOwnerManager -> ", newTicketOwnerManager) }}
                                            onInternalAuditorChange={(newInternalAuditor) => { console.log("newInternalAuditor -> ", newInternalAuditor) }}
                                            onIndependentAuditorChange={(newIndependentAuditor) => { console.log("newIndependentAuditor -> ", newIndependentAuditor) }}
                                            onTargetDateChange={(newTargetDate) => { subTaskUpdate("targetDate", newTargetDate) }}
                                            disabled={true}
                                            requestDate={null}
                                            creationDate={subtask?.creationDate || null}
                                            lastUpdate={subtask?.lastUpdate || null}
                                            responsiblePermission={!subtask?.subtaskPermissions.some((subPerm) => subPerm.permissions.responsible === "Editable")}
                                            approverPermission={true}
                                            internalAuditorPermission={true}
                                            independentAuditorPermission={true}
                                            targetDatePermission={!subtask?.subtaskPermissions.some((subPerm) => subPerm.permissions.targetDate === "Editable")}
                                            visibleResponsible={subtask?.subtaskPermissions.every((subPerm) => subPerm.permissions.responsible === "UnvisibleF") ? true : false}
                                            visibleApprover={true}
                                            visibleInternalAuditor={true}
                                            visibleIndependentAuditor={true}
                                            visibleTargetDate={subtask?.subtaskPermissions.every((subPerm) => subPerm.permissions.targetDate === "UnvisibleF") ? true : false}
                                            visibleRequestDate={true}
                                            visibleCreationDate={subtask?.subtaskPermissions.every((subPerm) => subPerm.permissions.creationDate === "UnvisibleF") ? true : false}
                                            visibleLastUpdate={subtask?.subtaskPermissions.every((subPerm) => subPerm.permissions.lastUpdateDate === "UnvisibleF") ? true : false}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container size={12} spacing={3}>
                        <Grid size={6}>
                            <Card square>
                                <CardContent>
                                    <TaskFileUploadField
                                        type='detail'
                                        allDocuments={subtask?.documents || []}
                                        onFileChange={(newFile) => { fileUpload(newFile) }}
                                        permission={!subtask?.subtaskPermissions.some((subPerm) => subPerm.permissions.documents === "Editable")}
                                    />
                                    <TaskFileTable
                                        documents={subtask?.documents || []}
                                        onFileChange={(newFiles) => { subTaskUpdate("documents", newFiles) }}
                                        filePermission={!subtask?.subtaskPermissions.some((subPerm) => subPerm.permissions.documents === "Editable")}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid size={6}>
                            <Card>
                                <CardContent>
                                    <IconButton>
                                        <SmartToyIcon style={{ color: "darkslateblue" }} />
                                    </IconButton>
                                    <AIDocumentSuggestion />
                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>

                    <Grid container size={12} sx={{ marginTop: "40px" }}>
                        <Card square>
                            <CardContent>
                                <Grid size={10}>
                                    <TaskCommentAndHistory
                                        taskComment={subtask?.comments || []}
                                        onCommentAndHistoryChange={(newComments) => { subTaskUpdate("comments", newComments) }}
                                        visibleInternalComment={subtask?.subtaskPermissions.every((subPerm) => subPerm.permissions.commentInternal === "UnvisibleF") ? true : false}
                                        visibleExternalComment={false}
                                        visibleInternalHistory={subtask?.subtaskPermissions.every((subPerm) => subPerm.permissions.historyInternal === "UnvisibleF") ? true : false}
                                        visibleExternalHistory={false}
                                        internalCommentPermission={!subtask?.subtaskPermissions.some((subPerm) => subPerm.permissions.commentInternal === "Editable")}
                                        externalCommentPermission={true}
                                        internalHistoryPermmission={!subtask?.subtaskPermissions.some((subPerm) => subPerm.permissions.historyInternal === "Editable")}
                                        externalHistoryPermission={""}
                                        type="subtask"
                                        allHistory={hist}
                                        onChangeTab={(tab) => { setCurrentTab(tab) }}
                                    />
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

            </DialogContent>

        </Dialog>

    );
};

export default SubtaskDetail;
import React from "react";
import TaskProjectSettings from "../../components/TaskManagement/TaskProjectSettings";

const ProjectSettings: React.FC = () => {

    return(
        <TaskProjectSettings />
    )

}

export default ProjectSettings;

import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
  Button,
  Menu,
  Checkbox,
  FormControlLabel,
  Box,
  Tooltip,
  TablePagination,
  CircularProgress,
} from '@mui/material';
import { Search, FilterList, Download } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface Change {
  field: string;
  before: string;
  after: string;
}

interface HistoryDetail {
  gmtDate: string;
  gmtTime: string;
  objectType: string;
  objectID: string;
  objectName: string;
  user: string;
  objectField: string;
  actionType: string;
  before: string;
  after: string;
  results: string;
}

interface HistoryEntry {
  logType: string;
  details: HistoryDetail;
}

interface AuditLogsPanoProps {
  logType: string;
}

const mockLogData: HistoryEntry[] = [
  {
    logType: 'Create',
    details: {
      gmtDate: '2024-12-01',
      gmtTime: '14:30',
      objectType: 'Task',
      objectID: 'T123',
      objectName: 'Task 1',
      user: 'User A',
      objectField: 'Name',
      actionType: 'Create',
      before: '',
      after: 'Task 1',
      results: 'Success',
    },
  },
  {
    logType: 'Update',
    details: {
      gmtDate: '2024-12-01',
      gmtTime: '15:00',
      objectType: 'Task',
      objectID: 'T124',
      objectName: 'Task 2',
      user: 'User B',
      objectField: 'Status',
      actionType: 'Update',
      before: 'Not Started',
      after: 'In Progress',
      results: 'Success',
    },
  },
];

const AuditLogsPano: React.FC<AuditLogsPanoProps> = ({ logType }) => {
  const [logData, setLogData] = useState<HistoryEntry[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedActions, setSelectedActions] = useState<string[]>([]);
  const [availableActions, setAvailableActions] = useState<string[]>([]);
  const [filterMenuAnchor, setFilterMenuAnchor] = useState<null | HTMLElement>(null);
  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { t } = useTranslation('global');


  const getLogs = useCallback(async () => {
    setLoading(true);
    try {
      // Use mock data
      setLogData(mockLogData);

      // Extract actions from mock data
      const actions = Array.from(new Set(mockLogData.map((log) => log.details.actionType)));
      setAvailableActions(actions);

      setLoading(false);
    } catch (error) {
      setError('Error fetching logs');
      setLoading(false);
      console.error('Error fetching logs:', error);
    }
  }, []);

  useEffect(() => {
    getLogs();
  }, [getLogs]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleActionToggle = (action: string) => {
    setSelectedActions((prevSelected) =>
      prevSelected.includes(action)
        ? prevSelected.filter((item) => item !== action)
        : [...prevSelected, action]
    );
  };

  const handleFilterMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setFilterMenuAnchor(event.currentTarget);
    setOpenFilterMenu(true);
  };

  const handleFilterMenuClose = () => {
    setOpenFilterMenu(false);
  };

  const handleDownload = () => {
    alert('Data downloaded!');
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData = logData.filter((entry) => {
    const isMatchingAction =
      selectedActions.length === 0 || selectedActions.includes(entry.details.actionType);
    const isMatchingSearch =
      entry.details.objectType.toLowerCase().includes(searchQuery.toLowerCase()) ||
      entry.details.objectID.toLowerCase().includes(searchQuery.toLowerCase()) ||
      entry.details.objectName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      entry.details.user.toLowerCase().includes(searchQuery.toLowerCase());
    return isMatchingAction && isMatchingSearch;
  });

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 3, borderRadius: 2 }}>
      <div style={{ padding: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <TextField
          label={t("audit_logs.search")}
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search sx={{ color: '#555' }} />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          size="small"
          sx={{ flex: 1, marginRight: 2 }}
        />

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title="Filter" arrow>
            <IconButton onClick={handleFilterMenuClick} sx={{ marginRight: 2 }}>
              <FilterList sx={{ color: '#555' }} />
            </IconButton>
          </Tooltip>

          <Button
            variant="contained"
            style={{ backgroundColor: "#0b2343" }}
            startIcon={<Download />}
            onClick={handleDownload}
            sx={{
              backgroundColor: '#007bff',
              '&:hover': { backgroundColor: '#0056b3' },
              textTransform: 'none',
              padding: '8px 16px',
            }}
          >
            {t("audit_logs.download")}
          </Button>
        </Box>
      </div>

      <Menu
        anchorEl={filterMenuAnchor}
        open={openFilterMenu}
        onClose={handleFilterMenuClose}
        PaperProps={{
          style: {
            width: 250,
            padding: '10px',
            boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.15)',
            borderRadius: '8px',
          },
        }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
          {t("audit_logs.filter_by_action")}
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {availableActions.map((action) => (
            <FormControlLabel
              key={action}
              control={
                <Checkbox
                  checked={selectedActions.includes(action)}
                  onChange={() => handleActionToggle(action)}
                  name={action}
                />
              }
              label={action}
            />
          ))}
        </Box>
      </Menu>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: 3 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error" sx={{ textAlign: 'center', padding: 3 }}>
          {error}
        </Typography>
      ) : (
        <TableContainer sx={{ borderRadius: 2, overflow: 'hidden', boxShadow: 2, marginTop: 2 }}>
          <Table sx={{ minWidth: 650 }} aria-label="log table">
            <TableHead>
              <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                <TableCell sx={{ fontWeight: 'bold' }}>{t("audit_logs.action_type")}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{t("audit_logs.gmt_date")}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{t("audit_logs.gmt_time")}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{t("audit_logs.object_type")}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{t("audit_logs.object_id")}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{t("audit_logs.object_name")}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{t("audit_logs.user")}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{t("audit_logs.object_field")}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{t("audit_logs.before")}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{t("audit_logs.after")}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{t("audit_logs.results")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((entry, index) => (
                  <TableRow key={index}>
                    <TableCell>{entry.details.actionType}</TableCell>
                    <TableCell>{entry.details.gmtDate}</TableCell>
                    <TableCell>{entry.details.gmtTime}</TableCell>
                    <TableCell>{entry.details.objectType}</TableCell>
                    <TableCell>{entry.details.objectID}</TableCell>
                    <TableCell>{entry.details.objectName}</TableCell>
                    <TableCell>{entry.details.user}</TableCell>
                    <TableCell>{entry.details.objectField}</TableCell>
                    <TableCell>{entry.details.before}</TableCell>
                    <TableCell>{entry.details.after}</TableCell>
                    <TableCell>{entry.details.results}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}
    </Paper>
  );
};

export default AuditLogsPano;
